<template>
  <v-container class="pa-0 mt-0">
    <v-row>
      <v-col>
         <v-img eager class="separator-size d-inline-flex" src="assets/separator-blue-top.svg" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2 class="turquesa--text display-2">EL EQUIPO</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-container>
          <v-row>
            <v-col cols="12" lg="8" offset-lg="2">
              <p class="chivo text-left">TOKENFIT propone revolucionar los modelos tradicionales de la industria inmobiliaria mediante la tecnología blockchain; transparente y segura.</p>
              <p class="chivo text-left">Somos un equipo de desarrolladores y analistas legales argentinos, apasionados por impulsar la tecnología blockchain en Real Estate. </p>
              <p class="chivo text-left">Nuestro propósito es generar la primera experiencia de subasta de un inmueble íntegramente en la blockchain, para que sea un antecedente al crecimiento de nuevos modelos de negocio descentralizados, rápidos y seguros en nuestro país y el mundo entero. </p>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1">
        <v-card flat dark color="azul2" class="pt-5 pb-5">
          <v-container>

            <v-row>
              <v-col cols="12">
                <h2 :class="['display-1 pt-5', $vuetify.breakpoint.mobile ? '' : 'pb-12']">LOS PIONEROS</h2>
              </v-col>
            </v-row>

            <v-row :class="[$vuetify.breakpoint.mobile ? '' : 'bg-equipo']">

              <v-col cols="10" offset="1" lg="5" offset-lg="1" >
                <v-container>
                  <v-row>
                    <v-col 
                      cols="12" 
                      :class="[$vuetify.breakpoint.mobile ? 'd-flex justify-center' : 'pr-10 pt-6']">
                      <img src="assets/logo-iparraguirre-cicchitti.png" style="width: 100%; max-width: 350px;" />
                    </v-col>
                    <v-col cols="12" :class="[$vuetify.breakpoint.mobile ? '' : 'pl-5 pr-10']">
                      <p class="chivo">Asesoramiento y asistencia jurídico contable en el ámbito tributario, comercial, concursal y desarrollos blockchain</p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              
              <v-col cols="10" :offset="$vuetify.breakpoint.mobile ? '1' : ''" lg="5">
                <div class="d-block" style="height: 97px"></div>
                <v-container>
                  <v-row>
                    <v-col cols="12" :class="[$vuetify.breakpoint.mobile ? 'd-flex justify-center' : 'pl-10']">
                      <img src="assets/logo-ieko.png"  style="width: 100%; max-width: 350px;" />
                    </v-col>
                    <v-col cols="12" :class="[$vuetify.breakpoint.mobile ? '' : 'pl-15']">
                      <p class="chivo">Tecnología de programación sustentable para finanzas</p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>

            </v-row>

            <v-row>
              <v-col cols="12">
                <h2 ref="contentActivator" class="display-1 pt-12">EL SPONSOR</h2>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col 
                cols="12"  
                class="d-flex justify-center">
                <div class="d-flex">
                  <img src="assets/separator-celest-left.svg" v-if="!$vuetify.breakpoint.mobile" width="80px" style="z-index: 9">
                  <img src="assets/logo-DSC.png" class="sponsor-margins" :style="{'max-width': $vuetify.breakpoint.mobile ? '290px' : '350px'}" />
                  <img src="assets/separator-celest-right.svg" v-if="!$vuetify.breakpoint.mobile" width="80px"  style="z-index: 9">
                </div>
              </v-col>
            </v-row>
            <v-row :class="[$vuetify.breakpoint.mobile ? '' : 'pl-5 ml-10']">
              <v-col cols="8" offset="2" lg="5" offset-lg="3">
                <p class="chivo">Pronto te contaremos quien es nuestro Sponsor</p>
              </v-col>
            </v-row>

          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Equipo',
  data() {
    return {
    }
  }
}
</script>

<style>
.bg-equipo {
  background-image: url('../../../public/assets/tokenfit-pioneros-back-01.svg'), url('../../../public/assets/tokenfit-pioneros-back-02.svg');
  background-size: 50%;
  background-position-x: 1px, 100%;
  background-position-y: 60px, 140px;
}
.sponsor {
  width: 350px;
  height: 141px;
  background-color: #999999;
  display: flex;
  font-size: 1.5em;
  color: #666666;
  justify-content: center;
  align-items: center;
}
.sponsor-margins {
    margin-left: -1px;
    margin-right: -1px;
}
</style>